<template>
  <div class="SecurityInfo">
    <div id="PDFIframe" class="PDFIframe"></div>
    <div style="background-color:white;padding:20px;height:100%">
      <van-form ref="submit" @submit="onSubmit">
        <!-- <van-field
          label-width="150"
          error-message-align="right"
          input-align="right"
          v-model="AddFormApplyParams.name"
          name="表单名称"
          colon
          required
          label="表单名称"
          placeholder="请输入表单名称"
          :rules="[{ required: true, message: '请输入表单名称' }]"
        /> -->
        <van-field
          readonly
          label-width="150"
          error-message-align="right"
          input-align="right"
          clickable
          colon
          required
          name="picker"
          :value="formTemplateName"
          label="选择模板"
          placeholder="点击选择模板"
          :rules="[{ required: true, message: '请选择模板' }]"
          @click="showPicker = true"
        />
        <van-popup
          label-width="150"
          input-align="right"
          v-model="showPicker"
          position="bottom"
        >
          <van-picker
            show-toolbar
            :columns="GetFullFormTempList"
            value-key="fmtp03"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <div v-for="(item, index) in tempFields" :key="item.fmfd01">
          <van-field
            input-align="right"
            v-if="item.fmfd04 == 1"
            label-width="150"
            v-model="item.fmfd02"
            :label="item.fmfd06"
            colon
            name="fmfd06"
            placeholder="请选择输入内容"
          />
          <van-field
            input-align="right"
            v-if="item.fmfd04 == 7"
            readonly
            label-width="150"
            v-model="item.fmfd02"
            :label="item.fmfd06"
            colon
            name="fmfd06"
            placeholder="请选择输入内容"
          />
          <template v-if="item.fmfd04 == 6">
            <van-cell
              :title="item.fmfd06"
              :value="item.fmfd02"
              @click="items = item"
            />
          </template>
          <van-cell
            v-if="item.fmfd04 == 2"
            :title="item.fmfd06"
            :value="item.fmfd02"
            @click="
              items = item;
              userList = item.fmfd02.split(',');
            "
          />
          <!-- popupShow = true;  -->
          <van-cell
            :title="item.fmfd06"
            class="flexLR"
            v-if="item.fmfd04 == 3 || item.fmfd04 == 4 || item.fmfd04 == 5"
          >
            <template slot="default">
              <div class="flexLR" style="justify-content: end;">
                <div class="flexLR" v-if="item.fmfd04 != 5">
                  <span style="margin-right:8px;color:rgb(25, 137, 250)">{{
                    item.fmfd02 != "" ? "已上传" : ""
                  }}</span>
                  <van-button
                    native-type="button"
                    size="mini"
                    icon="plus"
                    @click.stop="openUpdateFile(item.fmfd01)"
                  >
                    上传文件
                  </van-button>
                  <input
                    type="file"
                    style="display: none;"
                    :ref="item.fmfd01"
                    name="Files"
                    :accept="item.fmfd04 != 4 ? 'image/png' : 'video/mp4'"
                    @change="clickUpload($event, item)"
                  />
                </div>
                <div v-else style="width:100%" class="flexLR">
                  <van-button
                    style="width:100%"
                    native-type="button"
                    size="mini"
                    icon="service-o"
                    @click.stop="
                      items = item;
                      play(item);
                    "
                  >
                    {{ item.fmfd02 == "" ? "录音" : "播放录音" }}
                  </van-button>
                  <van-button
                    style="width:100%"
                    type="info"
                    @click="item.fmfd02 = ''"
                    native-type="button"
                    icon="revoke"
                    size="mini"
                    v-if="item.fmfd02 != ''"
                    >重录
                  </van-button>
                </div>
              </div>
            </template>
          </van-cell>
        </div>

        <audio ref="LogAudioPlayer" style="display:none;width:100%"></audio>

        <!-- <van-button size="mini" native-type="button" icon="plus" type="primary">上传文件</van-button> -->

        <!-- <van-field readonly clickable input-align="right" colon name="picker" label-width="100"
                    :value="auditName" label="选择审核流程" placeholder="点击选择模板" @click="showPicker2 = true" /> -->

        <van-popup v-model="showPicker2" position="bottom">
          <van-picker
            show-toolbar
            :columns="AuditFlowList"
            value-key="fafl03"
            @confirm="onConfirm2"
            @cancel="showPicker2 = false"
          />
        </van-popup>

        <van-calendar
          v-model="timeShow"
          @confirm="(val) => TimeonConfirm(val, items)"
        />

        <van-popup
          style="padding:20px"
          v-model="popupShow"
          round
          position="bottom"
          :style="{ height: '50%' }"
        >
          <div
            class="flexcolumn"
            style="height:100%;justify-content:space-between;align-items:start"
          >
            <van-checkbox-group v-model="userList">
              <van-checkbox
                style="margin:10px 0"
                v-for="(checkboxItem, index) in checkboxItemList"
                :key="checkboxItem.user01"
                :name="checkboxItem.user04"
                >{{ checkboxItem.user04 }}
              </van-checkbox>
            </van-checkbox-group>
            <div class="btn-group flexLR" style="width:100%;">
              <van-button
                style="width:49%"
                native-type="button"
                ref="regionResetBtn"
                round
                type="warning"
                size="small"
                @click="userList = []"
                class="btn-group-button"
              >
                重置</van-button
              >
              <van-button
                style="width:49%"
                native-type="button"
                @click="addUser"
                round
                type="info"
                size="small"
                class="btn-group-button"
                >确认
              </van-button>
            </div>
          </div>
        </van-popup>

        <!-- <div style="font-size:14px;color:#000;padding:16px">审核流程</div> -->

        <div class="flexWrap" v-if="detailList.length">
          <div
            v-for="(item, index) in detailList.filter((ele) => ele.fadt07 == 1)"
            :key="index"
            class="shlcDIV"
          >
            <div
              class="flexcolumn"
              style="height: 100%;position: relative;padding:5px"
            >
              <div
                style="text-align:left;width:100%;height:15%;color: #005aff;font-weight: bold;"
              >
                {{ item.fadt05 == 1 ? "会签" : "或签" }}
              </div>
              <div>{{ item.fadt03 }}</div>
              <div style="position:relative;z-index:1">{{ item.name }}</div>
              <div
                style="position:absolute;bottom:0;right:10px;color:#0095ff;z-index: 0;font-weight:bold;opacity: 0.2;font-size: 36px;"
              >
                {{ index + 1 }}
              </div>
            </div>
          </div>
          <div
            v-for="(item, index) in detailList.filter((ele) => ele.fadt07 == 2)"
            :key="index + detailList.filter((ele) => ele.fadt07 == 1).length"
            class="shlcDIV"
          >
            <div
              class="flexcolumn"
              style="height: 100%;position: relative;padding:5px"
            >
              <div
                style="text-align:left;width:100%;height:15%;color: #005aff;font-weight: bold;"
              >
                抄送人员
              </div>
              <div>{{ item.fadt05 }}</div>
              <div style="position:relative;z-index:1">{{ item.name }}</div>
              <div
                style="position:absolute;bottom:0;right:10px;color:#0095ff;z-index: 0;font-weight:bold;opacity: 0.2;font-size: 36px;"
              >
                {{
                  index + detailList.filter((ele) => ele.fadt07 == 1).length + 1
                }}
              </div>
            </div>
          </div>
        </div>
      </van-form>
      <div style="padding:25px 16px;">
        <van-button
          round
          block
          type="info"
          :loading="loading"
          loading-text="发布中..."
          @click.stop="$refs.submit.submit()"
          >发布</van-button
        >
      </div>
    </div>
    <van-popup round v-model="show" style="width:80%;height:60%;padding:20px;">
      <div
        style="width:100%;height:100%;justify-content: space-between;"
        class="flexcolumn"
      >
        <div
          style="height:95%"
          v-if="
            GetFullFormTempList.filter(
              (el) => el.fmtp01 == AddFormApplyParams.tempId
            ).length
          "
        >
          <iframe
            type="application/pdf"
            :src="
              $store.state.baseUrl +
                GetFullFormTempList.filter(
                  (el) => el.fmtp01 == AddFormApplyParams.tempId
                )[0].fmtp05
            "
            width="100%"
            height="100%"
            frameborder="0"
          ></iframe>
        </div>
        <van-button
          style="width:100%;margin-top: 10px;"
          type="info"
          @click="AddFormApply"
          round
          >发布</van-button
        >
      </div>
    </van-popup>
    <Recorderx ref="Recorderx"></Recorderx>
    <van-popup
      v-model="show1"
      round
      position="bottom"
      :style="{ height: '30%' }"
    >
      <div v-if="isLY">
        <div class="wave w1"></div>
        <div class="wave w2"></div>
      </div>
      <div class="flex" style="height: 100%;width:100%;padding:40px">
        <div class="flexcolumn">
          <van-icon name="close" size="40" @click="closeLY" />
          重录
        </div>
        <div class="diyButton" @click="startLY">
          {{ isLY ? "录音中..." : "录音" }}
        </div>
        <div class="flexcolumn">
          <van-icon @click="endLY" name="passed" size="40" />
          完成
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  GetNetDateTime,
  GetFullFormTemp,
  GetFormAuditFlowList,
  GetOneFormAuditFlow,
  GetOneFormTemp,
  GetProUsersList,
  AddSafeFormApply,
  GetNewFormApplyFieldSafe,
} from "@/api";
import Recorderx from "@/components/Recorderx";
import Pdfh5 from "pdfh5";

export default {
  name: "addQuantity",
  components: { Recorderx },
  data() {
    return {
      loading: false,
      list: [],
      value: "",
      username: "",
      password: "",
      GetFullFormTempList: [],
      showPicker: false,
      showPicker2: false,
      show: false,
      show1: false,
      isLY: false,
      AddFormApplyParams: {
        name: "表单名称",
        tempId: "",
        qid: "",
      },
      AuditFlowList: [],
      detailList: [],
      auditName: "",
      formTemplateName: "",
      formTemp: null,
      tempFields: [],
      timeShow: false,
      checkboxItemList: [],
      popupShow: false,
      userList: [],
      items: {},
      fileList: [],
      bdName: "",
      newDate: new Date().toLocaleDateString(),
    };
  },
  computed: {
    PrefixInteger() {
      return function(num, n) {
        return (Array(n).join(0) + num).slice(-n);
      };
    },
  },
  watch: {},
  methods: {
    openUpdateFile(id) {
      this.$refs[id][0].click();
    },
    GetNetDateTime() {
      GetNetDateTime().then((res) => {
        this.newDate = res.Data;
      });
    },
    GetNewFormApplyFieldSafe() {
      GetNewFormApplyFieldSafe({
        params: { xid: this.$store.state.pid, type: 3 },
      }).then((res) => {
        this.bdName =
          res.Data && res.Data.filter((el) => el.fafd03 == 7)[0].fafd06
            ? this.PrefixInteger(
                Number(res.Data.filter((el) => el.fafd03 == 7)[0].fafd06) + 1,
                4
              )
            : "0001";
      });
    },
    clickUpload(e, item) {
      item.fmfd02 = e.target.files[0];
      this.items = item;
    },
    fileChange(file) {
      this.items.fmfd02 = file.file;
    },
    addUser() {
      this.items.fmfd02 = this.userList.toString().substr(1);
      this.userList = [];
      this.popupShow = false;
    },
    GetProUsersList() {
      let params = {
        seachword: "",
        id: this.$store.state.pid,
        cid: this.$store.state.qid,
        bid: "null",
        pageIndex: 1,
        pageSize: 999999,
      };
      GetProUsersList({ params }).then((res) => {
        this.checkboxItemList = res.Data.list;
      });
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    TimeonConfirm(date, item) {
      item.fmfd02 = this.formatDate(date);
      this.timeShow = false;
    },
    GetFormAuditFlowList() {
      let params = {
        xid: this.$store.state.pid,
        searchword: "",
        pageIndex: 1,
        pageSize: 99999,
        type: 3,
      };
      GetFormAuditFlowList({ params }).then((res) => {
        this.AuditFlowList = res.Data.list;
        if (res.Data.list.length) {
          this.AddFormApplyParams.auditId = this.AuditFlowList[0].fafl01;
          this.auditName = this.AuditFlowList[0].fafl03;
          this.GetOneFormAuditFlow(this.AddFormApplyParams.auditId);
        }
      });
    },
    GetOneFormAuditFlow(id) {
      let params = { id };
      GetOneFormAuditFlow({ params }).then((res) => {
        this.detailList = res.Data.detailList;
      });
    },
    AddFormApply() {
      this.loading = true;
      let fd = new FormData();
      for (var key in this.AddFormApplyParams) {
        fd.append(key, this.AddFormApplyParams[key]);
      }
      this.tempFields.forEach((ele) => {
        fd.append(ele.fmfd06, ele.fmfd02);
      });
      fd.append("famp15", 3);
      try {
        AddSafeFormApply(fd).then((res) => {
          if (res.Code) {
            this.$Toast("添加成功");
            this.loading = false;
            this.$router.push({
              name: "QualityPatrol",
              query: {
                type: 2,
              },
            });
          }
        });
      } catch (error) {
        this.loading = false;
      }
    },
    GetFullFormTemp() {
      GetFullFormTemp({
        params: {
          xid: this.$store.state.pid,
          type: 3,
        },
      }).then((res) => {
        this.GetFullFormTempList = res.Data;
        this.formTemp = res.Data[0];
        this.AddFormApplyParams.tempId = res.Data[0].fmtp01;
      });
    },
    GetOneFormTemp(id) {
      let params = {
        id,
        type: 2,
      };
      GetOneFormTemp({ params }).then((res) => {
        //   过滤发起人需要填写的
        this.tempFields = res.Data.tempFields.filter((ele) => ele.fmfd05 == 0);
        this.tempFields.forEach((ele) =>
          ["3", "4", "5"].includes(ele.fmfd04)
            ? (ele.fmfd02 = [])
            : ele.fmfd04 == "2"
            ? (ele.fmfd02 = this.checkboxItemList.filter(
                (k) => k.user01 == this.$store.state.uid
              )[0].user04)
            : ele.fmfd04 == "7"
            ? (ele.fmfd02 = this.bdName)
            : ele.fmfd04 == 6
            ? (ele.fmfd02 = this.newDate)
            : (ele.fmfd02 = "")
        );
        new Pdfh5("#PDFIframe", {
          pdfurl: this.$store.state.baseUrl + res.Data.formTemp.fmtp05,
        });
      });
    },
    endLY() {
      this.$refs.Recorderx.recStop().then((blob) => {
        this.items.fmfd02 = blob || "";
        this.isLY = false;
        this.show1 = false;
      });
    },
    play() {
      let _this = this;
      if (this.items.fmfd02 == "") {
        this.show1 = true;
      } else {
        var audio = this.$refs.LogAudioPlayer;
        audio.controls = true;
        if (!(audio.ended || audio.paused)) {
          audio.pause();
        }
        audio.onerror = function(e) {
          _this.$Toast.fail("播放失败");
        };
        audio.src = (window.URL || webkitURL).createObjectURL(
          this.items.fmfd02
        );
        audio.play();
      }
    },
    startLY() {
      this.$refs.Recorderx.recOpen().then((res) => {
        this.isLY = res;
      });
    },
    closeLY() {
      this.isLY = false;
      this.$refs.Recorderx.recClose();
    },
    onConfirm(value) {
      this.formTemplateName = value.fmtp03;
      this.AddFormApplyParams.tempId = value.fmtp01;
      this.GetOneFormTemp(value.fmtp01);
      this.GetNewFormApplyFieldSafe();
      this.showPicker = false;
    },
    onConfirm2(value) {
      this.auditName = value.fafl03;
      this.GetOneFormAuditFlow(value.fafl01);
      this.showPicker2 = false;
    },
    onSubmit(errorInfo) {
      this.AddFormApply();
    },
    updateData() {
      this.getData();
      this.$emit("updateLoading", "消息");
    },
    async consentBtn(v) {
      let res = await HandleMsg({
        id: v.Id,
        type: v.Type,
        state: 1,
      });
      this.$CodeMessage(res);
      this.getData();
    },
    async refuseBtn(v) {
      let res = await HandleMsg({
        id: v.Id,
        type: v.Type,
        state: 2,
      });
      this.$CodeMessage(res);
      this.getData();
    },
    async deleteBtn(v) {
      let res = await DelMsg({
        id: v.Id,
        type: v.Type,
      });
      this.$CodeMessage(res, "操作成功");
      this.getData();
    },
    async getData() {
      let res = await GetMessage();
      if (res.Code > 0) this.list = res.Data;
    },
  },
  created() {
    this.GetNetDateTime();
    // this.GetNewFormApplyFieldSafe();
    this.GetFullFormTemp();
    this.GetProUsersList();
    // this.GetFormAuditFlowList();
    this.loading = false;
    this.AddFormApplyParams.qid = this.$store.state.qid;
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.SecurityInfo {
  margin: 0 auto;
  height: 100%;

  .wave {
    position: absolute;
    top: calc((100% - 60px) / 2);
    left: calc((100% - 60px) / 2);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-attachment: fixed;
    background-position: center center;
  }

  .shlcDIV {
    border: 1px solid rgb(236, 236, 236);
    min-width: 200px;
    margin: 12px;
    min-height: 200px;
    border-radius: 10px;
  }

  .w1 {
    z-index: 2;
    border: 6px solid red;
    background-size: auto 106%;
    animation: wave 1s infinite;
  }

  .w2 {
    z-index: 3;
    border: 6px solid red;
    background-size: auto 106%;
    animation: wave 1s 0.2s infinite;
  }

  .diyButton {
    z-index: 9;
    width: 210px;
    text-align: center;
    line-height: 210px;
    color: white;
    font-size: 30px;
    letter-spacing: 4px;
    box-shadow: 0 0 5px 5px red;
    background-color: red;
    height: 210px;
    margin: auto auto;
    border-radius: 50%;
  }

  @keyframes wave {
    0% {
      top: calc((100% - 60px) / 2);
      left: calc((100% - 60px) / 2);
      width: 60px;
      height: 60px;
    }

    100% {
      top: calc((100% - 250px) / 2);
      left: calc((100% - 250px) / 2);
      width: 250px;
      height: 250px;
      opacity: 0.4;
    }
  }
}
</style>
